import Vue from 'vue'
import VueRouter from 'vue-router'

// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push =function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Manager',
    component: () => import('../views/Manager.vue'),
    redirect: '/cdcha', //重定向到主页
    children: [
      { path: '/403', name: 'Auth', meta:{name: '无权限'}, component: () => import('../views/manage/Auth') },
      { path: 'home', name: 'Home', meta:{name: '系统首页'}, component: () => import('../views/manage/Home') },
      { path: 'user', name: 'User', meta:{name: '保函信息'}, component: () => import('../views/manage/User') },
      { path: 'bhuser', name: 'Bhuser', meta:{name: '用户信息'}, component: () => import('../views/manage/Bhuser') },
      { path: 'person', name: 'Person', meta:{name: '个人信息'}, component: () => import('../views/manage/Person') },
      { path: 'password', name: 'Password', meta:{name: '修改密码'}, component: () => import('../views/manage/Password') },
      { path: 'logs', name: 'Logs', meta:{name: '日志信息'}, component: () => import('../views/manage/Logs') },
    ]
  },
  { path: '/element', name: 'element', meta:{name: 'element例子'}, component: () => import('../views/Element.vue')},
  { path: '/baohan', name: 'baohan', meta:{name: '保函信息'}, component: () => import('../views/VserViews/Baohan')},
  { path: '/cdcha', name: 'Cdcha', meta:{name: '诚达查询'}, component: () => import('../views/VserViews/Cdcha')},
  { path: '/racha', name: 'Racha', meta:{name: '润安查询'}, component: () => import('../views/VserViews/Racha')},
  { path: '/register', name: 'Register', meta:{name: '润安用户注册'}, component: () => import('../views/VserViews/Register')},
  { path: '/cdregister', name: 'CdRegister', meta:{name: '诚达用户注册'}, component: () => import('../views/VserViews/CdRegister')},
  { path: '/login', name: 'Login', meta:{name: '登录'}, component: () => import('../views/Login')},
  { path: '*', name: 'Nnfide', meta:{name: '无法访问'}, component: () => import('../views/Nnfide')},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // to 时到达的路由信息
  // from 是开源的路由信息
  // next 是帮助我们跳转路由的函数
  let adminPaths = ['/user']
  let user = JSON.parse(localStorage.getItem('honey-user') || '{}')
  if (user.role !== '管理员' && adminPaths.includes(to.path)) {
    //如果当前登录的用户不是管理员，然后当前到达的路径是管理员才能有权限访问的路径是，我们让用户取到一个没有权限的页面，不让他访问实际的页面
    next('/403')
  }else {
    next()
  }


})

export default router
